
      import Chatbot from "https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js";

      Chatbot.init({
        chatflowid: "d452a8dd-8961-4507-9484-1e9077a65634",
        apiHost: "https://flowise.projects.codes", //"http://localhost:3000"
        chatflowConfig: {
          // topK: 2
        },
        theme: {
          button: {
            backgroundColor: "#3B81F6",
            right: 20,
            bottom: 20,
            size: "medium", // small | medium | large | number
            dragAndDrop: true,
            iconColor: "white",
            customIconSrc:
              "https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg",
            autoWindowOpen: {
              autoOpen: false, //parameter to control automatic window opening
              openDelay: 2, // Optional parameter for delay time in seconds
              autoOpenOnMobile: false, //parameter to control automatic window opening in mobile
            },
          },
          tooltip: {
            showTooltip: true,
            tooltipMessage: "Haii Pengunjung👋 Selamat Datang!",
            tooltipBackgroundColor: "rgba(0, 0, 0, 0.8)", // Updated for transparency
            tooltipTextColor: "white",
            tooltipFontSize: 16,
          },
          chatWindow: {
            showTitle: true,
            title: "SMP IP Yakin AI Chatbot",
            titleAvatarSrc:
              "https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg",
            showAgentMessages: true,
            welcomeMessage:
              "Haloo.. Selamat datang di website sekolah kami! Ada yang bisa aku bantu?",
            errorMessage: "Maaf, sistem chat sedang bermasalah.",
            backgroundColor: "#ffffff",
            backgroundImage: "enter image path or link", // If set, this will overlap the background color of the chat window.
            height: 550,
            width: 400,
            fontSize: 16,
            //starterPrompts: ['What is a bot?', 'Who are you?'], // It overrides the starter prompts set by the chat flow passed
            starterPromptFontSize: 15,
            clearChatOnReload: true, // If set to true, the chat will be cleared when the page reloads.
            botMessage: {
              backgroundColor: "#f7f8ff",
              textColor: "#303235",
              showAvatar: true,
              avatarSrc: "/img/avatarAI.png",
            },
            userMessage: {
              backgroundColor: "#3B81F6",
              textColor: "#ffffff",
              showAvatar: true,
              avatarSrc:
                "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png",
            },
            textInput: {
              placeholder: "Type your question",
              backgroundColor: "#ffffff",
              textColor: "#303235",
              sendButtonColor: "#3B81F6",
              maxChars: 50,
              maxCharsWarningMessage:
                "You exceeded the characters limit. Please input less than 50 characters.",
              autoFocus: true, // If not used, autofocus is disabled on mobile and enabled on desktop. true enables it on both, false disables it on both.
              sendMessageSound: true,
              // sendSoundLocation: "send_message.mp3", // If this is not used, the default sound effect will be played if sendSoundMessage is true.
              receiveMessageSound: true,
              // receiveSoundLocation: "receive_message.mp3", // If this is not used, the default sound effect will be played if receiveSoundMessage is true.
            },
            feedback: {
              color: "#303235",
            },
            footer: {
              textColor: "#303235",
              text: "Developed by",
              company: "Febriansyah",
              companyLink: "https://www.linkedin.com/in/febriansyah-dev",
            },
          },
        },
      });
    